import PropTypes from "prop-types";
import React from "react";

import { Box } from "@mui/material";

export default function BackgroundComponentLayout({ children, ...props }) {
  return (
    <Box
      {...props}
      sx={{
        py: 2,
        backgroundColor: "secondary.linen",
        borderRadius: 2,
        ...props.sx,
      }}
    >
      {children}
    </Box>
  );
}

BackgroundComponentLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

BackgroundComponentLayout.defaultProps = {
  sx: {},
};
