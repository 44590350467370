import { Stack, Typography } from '@mui/material';
import Image from 'next/image';
import PropTypes from 'prop-types';
import React from 'react';
import HighlightedText from '../HighlightedText';

export default function TitleBodyPoints(props) {
  return (
    <Stack gap={{ xs: 2.5, md: 5 }}>
      <Stack gap={2.5}>
        <HighlightedText
          variant="h3"
          paragraph={props.title}
          highlights={[props.highlightText]}
          {...props.titleProps}
        />

        {props.body && (
          <Typography
            variant="body"
            color="text.primary"
            fontFamily="Inter"
            whiteSpace="pre-line"
          >
            {props.body}
          </Typography>
        )}
      </Stack>

      {props.points && (
        <Stack gap={2}>
          {props.points.map((item, index) => (
            <Stack direction="row" gap={1} key={index}>
              <Image
                src={'/assets/images/send.svg'}
                width={24}
                height={24}
                alt=">"
              />

              <Typography variant="body" fontFamily={'Inter'}>
                {item}
              </Typography>
            </Stack>
          ))}
        </Stack>
      )}
    </Stack>
  );
}

TitleBodyPoints.propTypes = {
  body: PropTypes.string,
  points: PropTypes.arrayOf(PropTypes.string),
  highlightText: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleProps: PropTypes.object,
};

TitleBodyPoints.defaultProps = {
  titleProps: {},
};
