import PropTypes from "prop-types";
import { Chip } from "@mui/material";

import React from "react";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

export default function Pill({ label, ...props }) {
  return (
    <Chip
      icon={<FiberManualRecordIcon fontSize="0.5rem" color="text.primary" />}
      label={label}
      sx={{
        fontWeight: 600,
        fontSize: "14px",
        p: "0.625rem 0.75rem",
      }}
      {...props}
    />
  );
}

Pill.propTypes = {
  title: PropTypes.string.isRequired,
};
