import LinearProgress from "@mui/material/LinearProgress";
import PropTypes from "prop-types";

const Loader = ({ children, loading = false, block = false }) => {
  return (
    <>
      {loading ? <LinearProgress /> : null}
      <>{block && loading ? null : children}</>
    </>
  );
};

Loader.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool.isRequired,
  block: PropTypes.bool,
};

export default Loader;
