import PropTypes from "prop-types";
import React from "react";

import { Container } from "@mui/material";

export default function HomeComponentContainer({ children, ...props }) {
  return <Container {...props}>{children}</Container>;
}

HomeComponentContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
