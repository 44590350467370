import React from "react";

import { Button, useMediaQuery } from "@mui/material";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

export default function ButtonWithArrow({ children, ...props }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Button
      variant="containedGreen"
      endIcon={
        <ArrowCircleRightIcon
          sx={{
            fontSize: "2.1875rem !important",
          }}
        />
      }
      {...props}
      sx={{
        paddingRight: "0.625rem",
        marginRight: isMobile ? "10px" : "20px",
      }}
    >
      {children}
    </Button>
  );
}
