import PropTypes from "prop-types";
import * as React from "react";
import { Typography } from "@mui/material";

const HighlightedText = ({ paragraph, highlights, ...props }) => {
  // Function to split and highlight the text
  const highlightText = (text, highlights) => {
    const result = [];
    let remainingText = text;

    highlights?.forEach((highlight, index) => {
      const regex = new RegExp(`(${highlight})`, "gi");
      const parts = remainingText?.split(regex);

      result?.push(
        ...(parts && parts?.length
          ? parts?.map((part, partIndex) => (
              <React.Fragment key={index + "-" + partIndex}>
                {part.toLowerCase() === highlight?.toLowerCase() ? (
                  <Typography
                    {...props}
                    component="span"
                    color="text.secondaryPink"
                    key={index + "-" + partIndex}
                  >
                    {part}
                  </Typography>
                ) : (
                  part
                )}
              </React.Fragment>
            ))
          : [])
      );

      remainingText = parts && parts[parts?.length - 1];
    });

    return result;
  };

  const highlightedParagraph = React.useMemo(
    () => highlightText(paragraph, highlights),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paragraph, highlights]
  );

  return (
    <Typography variant="body1" color="text.primary" {...props}>
      {highlightedParagraph}
    </Typography>
  );
};

HighlightedText.propTypes = {
  highlights: PropTypes.arrayOf(PropTypes.string),
  paragraph: PropTypes.string.isRequired,
};

export default HighlightedText;
