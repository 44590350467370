import PropTypes from "prop-types";
import dynamic from "next/dynamic";
import Loader from "@components/common/Loader";
import { Box } from "@mui/material";

const Navbar = dynamic(() => import("@components/common/Navbar"));
const Footer = dynamic(() => import("@components/common/Footer"));

const Layout = ({
  children,
  loading = false,
  footerProps = {},
  layoutProps = {
    sectionProps: {},
  },
}) => {
  const {
    sectionProps: { sx: sectionStyles = {}, restSectionProps = {} } = {},
  } = layoutProps;
  return (
    <>
      <Loader loading={loading} />
      <Navbar />
      <Box
        component="section"
        sx={{
          mt: 8,
          minHeight: "75vh",
          position: "relative",
          mx: "auto",
          ...sectionStyles,
        }}
        {...restSectionProps}
      >
        {children}
      </Box>
      <Footer {...footerProps} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  footerProps: PropTypes.object,
  floatingActionButton: PropTypes.bool,
};

export default Layout;
