import { gql } from "@apollo/client";

const CREATE_LEAD = gql`
  mutation Leads($phone: String!) {
    createLead(phone: $phone) {
      phone
    }
  }
`;

export const CREATE_LEAD_EMAIL_AND_COUNTRY = gql`
  mutation CreateLead($email: String, $country: String, $campaign: String) {
    createLead(email: $email, country: $country, campaign: $campaign) {
      id
      email
      country
    }
  }
`;

export const CREATE_LEAD_EMAIL = gql`
  mutation Leads($email: String!) {
    createLeadEmail(email: $email) {
      email
    }
  }
`;

const ADD_LEAD_PREFERENCE = gql`
  mutation AddLeadPreferences(
    $space_id: Int!
    $light_levels: Int!
    $distance_from_source: Int!
    $water_frequency_id: Int!
    $budget: String!
    $size: String!
    $plant_category_id: Int!
    $email: String
    $phone: String
  ) {
    addLeadPreferences(
      email: $email
      phone: $phone
      space_id: $space_id
      light_levels: $light_levels
      distance_from_source: $distance_from_source
      water_frequency_id: $water_frequency_id
      budget: $budget
      size: $size
      plant_category_id: $plant_category_id
    ) {
      id
    }
  }
`;

const UPDATE_EMAIL = gql`
  mutation updateEmailByIp($email: String!) {
    updateEmailByIp(email: $email) {
      id
      email
    }
  }
`;

const SEND_EMAIL_FLASH_CARD = gql`
  mutation sendFlashCardEmail(
    $plant_name: String!
    $mini_description: String!
    $scientific_name: String!
    $light_levels: String!
    $care: String!
    $water_frequency: String!
    $growing_medium: String
    $planter: String
    $accessories: String
    $imageURI: String!
    $email: String
  ) {
    sendFlashCardEmail(
      email: $email
      plant_name: $plant_name
      mini_description: $mini_description
      scientific_name: $scientific_name
      light_levels: $light_levels
      care: $care
      water_frequency: $water_frequency
      growing_medium: $growing_medium
      planter: $planter
      accessories: $accessories
      imageURI: $imageURI
    )
  }
`;

const SUBSCRIBE_CARE = gql`
  mutation subscribeUserToCare($phone: String!) {
    subscribeUserToCare(phone: $phone)
  }
`;

const SEND_PLANT_MATCH_MESSAGE = gql`
  mutation SendPlantMatchMessage(
    $plant_name: String!
    $mini_description: String!
    $light_levels: String!
    $care: String!
    $water_frequency: String!
    $phone: String!
  ) {
    sendPlantMatchMessage(
      phone: $phone
      plant_name: $plant_name
      mini_description: $mini_description
      light_levels: $light_levels
      care: $care
      water_frequency: $water_frequency
    )
  }
`;

const CONTACT_US = gql`
  mutation ContactUs($email: String!, $subject: String!, $message: String!) {
    contactUs(email: $email, subject: $subject, message: $message)
  }
`;

const SEND_WA_MESSAGE = gql`
  mutation ContactUs($message: String!, $phone: String!, $media: String) {
    sendUserContentMessage(message: $message, phone: $phone, media: $media)
  }
`;

export {
  CREATE_LEAD,
  ADD_LEAD_PREFERENCE,
  UPDATE_EMAIL,
  SEND_EMAIL_FLASH_CARD,
  SUBSCRIBE_CARE,
  SEND_PLANT_MATCH_MESSAGE,
  CONTACT_US,
  SEND_WA_MESSAGE,
};
