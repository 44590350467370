import PropTypes from "prop-types";
import React from "react";
import { Stack, Typography } from "@mui/material";
import TitleBodyPoints from "@components/common/TitleBodyPoints";
import ContactSection from "@components/PlantBundles/Contact";
import BackgroundComponentLayout from "./BackgroundComponentLayout";
import HomeComponentContainer from "./HomeComponentContainer";
import PillWrapper from "./PillWrapper";

export default function ContactUs({ title, body }) {
  return (
    <BackgroundComponentLayout
      sx={{
        py: { xs: 6.5, sm: 12.5 },
      }}
    >
      <HomeComponentContainer>
        <Stack gap={6.25} px={{ xs: 2, md: 6 }} alignItems="center">
          <PillWrapper
            gap={2.5}
            alignItems="center"
            textAlign="center"
            label="CONTACT US"
          >
            <Stack gap={2.5}>
              <TitleBodyPoints
                title={title}
                highlightText={"Us"}
                body={body}
                titleProps={{
                  sx: {
                    fontSize: { xs: "2rem", md: "3.75rem" },
                  },
                }}
              />
              <Typography
                variant="body"
                color="text.primary"
                fontFamily="Inter"
              >
                Leave your details below, we will get back to you within 24 hrs.
              </Typography>
            </Stack>
          </PillWrapper>

          <ContactSection />
        </Stack>
      </HomeComponentContainer>
    </BackgroundComponentLayout>
  );
}

ContactUs.propTypes = {
  body: PropTypes.string,
  title: PropTypes.string,
};

TitleBodyPoints.defaultProps = {
  title: "Talk to Us",
  body: "Get custom quotes and offers for your complete space makeover. From green space design to delivery, maintenance and free plant replacements, we do it all so you can sit back, relax & enjoy the nature.",
};
