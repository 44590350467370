import React from "react";
import PropTypes from "prop-types";

import { Box, Stack } from "@mui/material";

import Pill from "@components/common/Pill";

export default function PillWrapper({ label, children, ...props }) {
  return (
    <Stack gap={2} {...props}>
      <Box>
        <Pill label={label} />
      </Box>

      {children}
    </Stack>
  );
}

PillWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
};
